import { CarImage } from './carProfile'

export enum DamageReportStatus {
  OPENED = 'opened',
  RESOLVED = 'resolved',
}

export interface DamageReport {
  images: CarImage[]
  id: string
  trip_id: string
  user_id: string
  description: string
  status: DamageReportStatus
  createdAt: string
  updatedAt: string
}
