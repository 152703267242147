import {
  getInitialCommonModuleState,
  getInitialDataModuleState,
  getInitialListModuleState,
  getMultipleUpdatesModuleState,
  getPagintaionModuleState,
} from '@/helpers/store-state'

import { TripDetailsModule, State, TripLogsModule, InsuranceLogModule } from '.'

export const getInitialTripDetailsState = (): TripDetailsModule => ({
  ...getInitialDataModuleState(),

  cancellationRequestsModule: {
    resolving: getMultipleUpdatesModuleState(),
    commentUpdating: getMultipleUpdatesModuleState(),
  },

  damageReportsModule: {
    updating: getMultipleUpdatesModuleState(),
  },
})

export const getInitialTripLogsModuleState = (): TripLogsModule => ({
  listModule: getInitialListModuleState(),
  creation: getInitialCommonModuleState(),
})

export const getInitialInsuranceLogsModuleState = (): InsuranceLogModule => ({
  listModule: getInitialListModuleState(),
  creation: getInitialCommonModuleState(),
})

export const getInitialTripsState = (): State => ({
  listModule: getPagintaionModuleState(),

  tripsDetails: {},

  tripsLogs: {},

  insuranceLogs: {},
})
