import { FieldMeta } from '@/models/common'
import { ExtraJsonsKey, TripFull } from '@/models/trip'
import { BracketTypes, replaceBrackets } from './strings'

type TripKeys = keyof TripFull

const TRIP_POSTFIX_MAP: { [key in TripKeys]?: string } = {
  rental_fee: '[$/day]',
  rent_duration: '[day]',
  admin_fee: '[%]',
  promotional_admin_fee: '[%]',
  effective_admin_fee: '[%]',
  host_profit: '[$]',
  admin_profit: '[$]',
  deposit: '[$]',
  insurance_fee: '[$/trip]',
  total_insurance: '[$/trip]',
  total: '[$]',
  extra_distance_charge: '[$/km]',
}

const PRICE_KEYS: TripKeys[] = [
  'total',
  'deposit',
  'rental_fee',
  'host_profit',
  'admin_profit',
  'insurance_fee',
  'total_insurance',
  'admin_fee',
  'promotional_admin_fee',
  'effective_admin_fee',
]

export const getTripKeyMeta = (
  key: TripKeys,
  brackets?: BracketTypes
): FieldMeta => {
  const meta: FieldMeta = {
    postfix: TRIP_POSTFIX_MAP[key] || '',
    isPriceKey: PRICE_KEYS.includes(key),
  }

  if (brackets) meta.postfix = replaceBrackets(meta.postfix, brackets)

  return meta
}

const EXTRAS_POSTFIX_MAP: { [key in ExtraJsonsKey]?: string } = {
  full_tank: '[$/trip]',
  chauffeur_service: '[$/day]',
}
// All keys are pricing fields
export const getTripExtrasPostfix = (
  key: ExtraJsonsKey,
  brackets?: BracketTypes
): string => {
  const postfix = EXTRAS_POSTFIX_MAP[key] || ''
  return brackets ? replaceBrackets(postfix, brackets) : postfix
}
